.Footer {
  margin: 64px 0 0;
  padding: 64px 32px 64px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 16px;
  line-height: calc( 16px * 1.615);
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(255, 255, 255, 0.4); }
  .Footer .oca {
    background-image: none; }
    .Footer .oca img {
      width: 120px; }
