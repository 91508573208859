.News {
  display: flex;
  justify-content: center; }
  .News .link {
    margin-bottom: 32px; }
    .News .link:last-child {
      margin-bottom: 0; }
    .News .link p {
      margin: 0; }
  .News .promo-event {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: rgba(255, 194, 51, 0.2);
    border: 2px solid #b71c1c; }
    .News .promo-event .event-title {
      font-size: 1.2rem;
      font-family: 'Playfair Display', serif; }
    .News .promo-event .event-description {
      margin-top: .5rem; }
  .News .article {
    position: relative; }
    .News .article .header {
      max-width: 100%; }
      .News .article .header .eyebrows {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        color: rgba(0, 0, 0, 0.54); }
      .News .article .header h1 {
        color: #FF8F00; }
    .News .article .article-footer {
      margin-top: 16px;
      padding-top: 24px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      text-align: center;
      font-size: 1rem; }
  .News .content-container.article {
    max-width: 800px;
    width: 100%; }
    .News .content-container.article.pdf {
      padding: 16px; }
      .News .content-container.article.pdf canvas {
        width: 800px !important;
        height: calc( 800 / 612* 792px) !important; }
    .News .content-container.article .body {
      max-width: 100%; }
      .News .content-container.article .body img {
        display: block;
        width: 100%; }
      .News .content-container.article .body .image-caption {
        color: rgba(0, 0, 0, 0.54);
        font-size: .9rem; }
      .News .content-container.article .body .section {
        margin-bottom: 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
        .News .content-container.article .body .section .title {
          margin-top: 0;
          margin-bottom: 0;
          font-family: 'Playfair Display', serif;
          color: #FF8F00; }
        .News .content-container.article .body .section .author {
          margin-top: 4px;
          font-family: 'Playfair Display', serif;
          color: rgba(0, 0, 0, 0.54); }
        .News .content-container.article .body .section .article-body {
          margin-top: 16px;
          text-align: justify; }
          .News .content-container.article .body .section .article-body .scripture {
            font-family: 'Playfair Display', serif; }
          .News .content-container.article .body .section .article-body .quote {
            padding: 0 16px;
            font-family: 'Playfair Display', serif;
            color: #FF8F00;
            text-align: center; }
      .News .content-container.article .body .main-article {
        position: relative;
        width: 100%; }
      .News .content-container.article .body .cols {
        display: flex;
        flex-direction: row;
        width: 100%; }
        @media screen and (max-width: 768px) {
          .News .content-container.article .body .cols {
            flex-direction: column; } }
        .News .content-container.article .body .cols .section:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none; }
        .News .content-container.article .body .cols .article-body {
          font-size: 1.1rem;
          text-align: justify; }
        .News .content-container.article .body .cols .left {
          flex: 3;
          padding-right: 16px; }
          @media screen and (max-width: 768px) {
            .News .content-container.article .body .cols .left {
              flex: 1;
              padding-right: 0; } }
          .News .content-container.article .body .cols .left .eyebrow {
            margin-bottom: .5rem;
            font-size: 1.4rem; }
        .News .content-container.article .body .cols .right {
          flex: 2;
          padding-left: 16px; }
          @media screen and (max-width: 768px) {
            .News .content-container.article .body .cols .right {
              flex: 1;
              padding-left: 0; } }
          .News .content-container.article .body .cols .right .section .eyebrow {
            margin-bottom: .5rem;
            font-size: 1.4rem; }
          .News .content-container.article .body .cols .right .section .title {
            font-size: 1.4rem; }
          .News .content-container.article .body .cols .right .section ul.crossed-list {
            margin-top: 0;
            margin-left: .5rem;
            padding-left: 1rem;
            text-indent: -1rem;
            list-style: none; }
            .News .content-container.article .body .cols .right .section ul.crossed-list li:before {
              content: '\2720';
              margin-right: .5rem; }
