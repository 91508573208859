.Giving {
  display: flex;
  justify-content: center; }
  .Giving .eyebrow {
    font-family: 'Playfair Display', serif;
    font-size: 1.5em; }
  .Giving .link {
    margin-bottom: 32px; }
    .Giving .link:last-child {
      margin-bottom: 0; }
    .Giving .link p {
      margin: 0; }
  .Giving .TithelyButton {
    margin-left: auto;
    margin-right: auto; }
