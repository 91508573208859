.Belief {
  display: flex;
  justify-content: center; }
  .Belief .content-container {
    padding: 64px; }
    .Belief .content-container .header {
      padding: 0 16px; }
  .Belief .section {
    margin-bottom: 32px; }
    .Belief .section:last-child {
      margin-bottom: 0; }
    .Belief .section h3 {
      margin-bottom: 8px; }
    .Belief .section img {
      display: block;
      max-height: 320px;
      margin: 0 auto 16px;
      padding: 16px;
      border: 1px solid #FF8F00; }
    @media screen and (max-width: 640px) {
      .Belief .section img {
        max-height: 100vh;
        max-width: calc( 100% - 32px); } }
