.AplosModal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 30; }
  .AplosModal .modal-backdrop {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 30; }
  .AplosModal iframe {
    z-index: 40; }
  .AplosModal .modal-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 38px;
    width: 100%;
    background-color: transparent;
    color: #fff;
    z-index: 50; }
    .AplosModal .modal-nav-container .modal-nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      z-index: 40; }
      .AplosModal .modal-nav-container .modal-nav button {
        padding: 10px 1.2rem;
        background: none;
        border: none;
        font-size: 1rem;
        color: #f0eee9; }
        .AplosModal .modal-nav-container .modal-nav button:hover {
          cursor: pointer; }
