.Page {
  padding-top: 88px; }
  .Page .content-container {
    margin: 64px 32px; }
    @media screen and (max-width: 768px) {
      .Page .content-container {
        width: 100%;
        margin: 0 0;
        padding: 64px 32px; } }
    .Page .content-container h1 {
      margin: 0;
      font-weight: 400;
      font-size: calc( 18px + 1.5vw); }
    .Page .content-container h2 {
      margin: 8px 0 16px 0;
      font-weight: 400;
      font-size: calc( 16px + .8vw);
      color: rgba(0, 0, 0, 0.7); }
    .Page .content-container h3 {
      margin: 0;
      font-weight: 400; }
    .Page .content-container .header {
      max-width: calc(19px / 1.615 * 60);
      margin-bottom: 32px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
    .Page .content-container .body {
      max-width: calc( 19px / 1.615 * 60);
      font-family: 'Roboto', 'system', sans-serif;
      font-size: 19px;
      line-height: calc( 19px * 1.615); }

.Blog .content-container {
  padding: 64px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 32px; }
  .grid-3 .grid-item.one {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2; }
  .grid-3 .grid-item.two {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2; }
  .grid-3 .grid-item.three {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2; }
  @media screen and (max-width: 1024px) {
    .grid-3 {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: 32px; }
      .grid-3 .grid-item.one {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2; }
      .grid-3 .grid-item.two {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3; }
      .grid-3 .grid-item.three {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4; } }

.donate-button {
  margin: 0 auto;
  background-color: #009e74;
  font-family: inherit;
  font-weight: bold;
  font-size: 19px;
  padding: 15px 70px;
  border-radius: 4px;
  cursor: pointer;
  background-image: none;
  color: white;
  text-shadow: none;
  display: inline-block;
  float: none;
  border: none; }
