.Nav {
  width: 100%;
  z-index: 20; }
  .Nav .nav-container {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .Nav .nav-container nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
      padding-left: 16px; }
      .Nav .nav-container nav button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 87px;
        padding: 0 16px;
        margin-right: 16px;
        border: none;
        background-color: transparent;
        font-size: 32px;
        color: #b71c1c; }
        @media screen and (max-width: 768px) {
          .Nav .nav-container nav button {
            position: fixed;
            top: 0;
            right: 0;
            z-index: 110; } }
        .Nav .nav-container nav button:hover {
          cursor: pointer; }
        .Nav .nav-container nav button .material-icons {
          background-color: transparent;
          font-size: 48px; }
      .Nav .nav-container nav a {
        padding: 12px 12px;
        background-image: none; }
      .Nav .nav-container nav .links {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 16px; }
        .Nav .nav-container nav .links a {
          display: inline-block;
          font-family: 'Playfair Display', serif;
          font-size: 20px; }
        .Nav .nav-container nav .links .building-program-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          position: relative;
          padding: 4px 8px;
          border: 1px solid #FF8F00; }
          .Nav .nav-container nav .links .building-program-link .bp-domes {
            position: relative;
            height: 48px;
            margin-right: 8px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 1; }
          .Nav .nav-container nav .links .building-program-link .bp-text {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 22px; }
        @media screen and (max-width: 768px) {
          .Nav .nav-container nav .links.closed {
            display: none; }
          .Nav .nav-container nav .links.open {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            min-height: 120vh;
            width: 100%;
            padding: 32px 0;
            background-color: rgba(255, 255, 255, 0.94);
            z-index: 100; }
          .Nav .nav-container nav .links a {
            font-size: 32px; }
          .Nav .nav-container nav .links .building-program-link {
            padding: 6px 12px; }
            .Nav .nav-container nav .links .building-program-link .bp-domes {
              height: 60px; }
            .Nav .nav-container nav .links .building-program-link .bp-text {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 28px;
              line-height: 32px; } }

.headroom {
  z-index: 20 !important; }
