@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 400;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma"); }

.Home .hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: url("../../assets/images/bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  .Home .hero h1 {
    margin: 0 32px;
    padding-bottom: 16px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    font-weight: 400;
    font-size: calc( 24px + 3vw);
    color: rgba(0, 0, 0, 0.84); }
  .Home .hero h2 {
    margin: 24px 32px 0 32px;
    text-align: center;
    font-family: 'Roboto', 'system', sans-serif;
    font-weight: 400;
    font-size: calc( 16px + 1.5vw);
    color: rgba(0, 0, 0, 0.6); }
  .Home .hero .building-program-alert {
    display: none;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 8px 16px 8px 8px;
    border-radius: 32px;
    text-decoration: none;
    border-bottom: none;
    background-image: none; }
    @media screen and (max-width: 768px) {
      .Home .hero .building-program-alert {
        display: flex; } }
    .Home .hero .building-program-alert .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      padding: 4px 8px 4px;
      background-color: #b71c1c;
      border-radius: 16px;
      color: #fff;
      font-size: .8rem;
      font-weight: bold; }
    .Home .hero .building-program-alert .text {
      color: #fff; }

.Home .content {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.03), 0 -2px 4px rgba(0, 0, 0, 0.03), 0 -2px 8px rgba(0, 0, 0, 0.03); }
  .Home .content .section {
    box-sizing: border-box;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 64px 32px;
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.84); }
    @media screen and (max-width: 768px) {
      .Home .content .section {
        padding-top: 0px; } }
    .Home .content .section h1 {
      font-weight: 400;
      font-size: calc( 18px + 1.5vw); }
      .Home .content .section h1.section-title {
        margin-bottom: 0;
        padding-top: 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.84); }
    .Home .content .section h2 {
      font-weight: 400;
      font-size: calc( 16px + .8vw); }
      .Home .content .section h2.section-subtitle {
        margin-top: 0;
        padding-top: 8px;
        font-family: 'Roboto', 'system', sans-serif;
        color: rgba(0, 0, 0, 0.54); }
    .Home .content .section.cross-section {
      display: flex;
      align-items: center;
      justify-content: center; }
      .Home .content .section.cross-section .cross-icon {
        position: absolute;
        margin-top: -28vh;
        margin-left: auto;
        margin-right: auto; }
        @media screen and (max-width: 768px) {
          .Home .content .section.cross-section .cross-icon {
            transform: scale(0.5); } }
      .Home .content .section.cross-section .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 32px 0;
        padding: 48px 0;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        text-align: center;
        z-index: 10; }
        .Home .content .section.cross-section .box h1 {
          margin: 0 32px;
          padding-bottom: 16px;
          border-bottom: 2px solid rgba(0, 0, 0, 0.1);
          text-align: center; }
        .Home .content .section.cross-section .box h3 {
          margin: 24px 32px 0 32px;
          text-align: center;
          font-family: 'Roboto', 'system', sans-serif;
          font-weight: 400;
          font-size: calc( 16px + .8vw);
          color: rgba(0, 0, 0, 0.6); }
    .Home .content .section.welcome {
      padding-top: 0; }
      .Home .content .section.welcome .blurb {
        font-size: calc( 16px + .6vw);
        line-height: calc( (16px + .6vw) * 1.615);
        color: rgba(0, 0, 0, 0.84);
        font-family: 'Roboto', 'system', sans-serif; }
    .Home .content .section .grid-3 {
      margin-top: 48px; }
      @media screen and (max-width: 768px) {
        .Home .content .section .grid-3 {
          margin-top: 16px; } }
      @media screen and (max-width: 768px) {
        .Home .content .section .grid-3 .grid-item {
          margin-top: 24px; } }
      .Home .content .section .grid-3 .grid-item span {
        font-size: calc( 16px + .4vw);
        line-height: calc( (16px + .4vw) * 1.615);
        font-family: 'Roboto', 'system', sans-serif; }
      .Home .content .section .grid-3 .grid-item h2 {
        margin-top: 0;
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
